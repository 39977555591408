/**
 * This component is for reports
 */
import React from 'react'
import {
  ButtonGroup,
  Button as MuiButton,
  Stack,
  Typography,
  ButtonProps as MuiButtonProps,
} from '@mui/material'
import { SCORING_GUIDE } from 'views/assessments/utils/scoringGuide'
import { parseMetadata } from 'views/assessments/utils/parse-metadata'
import { getAssessmentScore } from 'views/assessments/utils/get-assessment-score'

// we're using button group to cleanly highlight severity

const Button: React.FC<MuiButtonProps> = ({ children, ...muiButtonProps }) => {
  return (
    <MuiButton
      {...muiButtonProps}
      sx={{
        color: 'var(--black) !important',
        '& > span': {
          color: 'var(--black) !important',
        },
        padding: '0.5rem',
        border: '0 !important',
        mx: '1px',
        py: 2,
        ...muiButtonProps.sx,
      }}
    >
      {children}
    </MuiButton>
  )
}

interface MostRecentAssessmentProps {
  assessment: { updatedAt?: string; data?: { answers?: JSON } }
  subScore: any
  metadata?: JSON
}

const formatUpdatedAt = (updatedAt: string | null | undefined) => {
  if (!updatedAt) {
    return null
  } else {
    const date = new Date(updatedAt)

    // Format the date
    var formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    return formattedDate
  }
}

function addOpacityToRgb(rgbString, opacity) {
  // Validate and extract the numbers from the RGB string
  const match = rgbString.match(/^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/)

  if (!match) {
    // throw new Error('Invalid RGB string.');
    return '#f5f5f5'
  }

  // Extract the red, green, and blue values
  const [, r, g, b] = match

  // Return the RGBA string with the provided opacity
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export default function MostRecentAssessment({ assessment, metadata }: MostRecentAssessmentProps) {
  const { assessmentName: metadataAssessmentName, isAce, isBBCSS, subScaleOrder } = parseMetadata(
    metadata
  )

  const assessmentDetails = SCORING_GUIDE.find(({ assessmentName }) =>
    assessmentName.includes(metadataAssessmentName)
  )

  if (!assessmentDetails) {
    return null
  }

  // get summary score color and and set opacity
  const updatedAt = formatUpdatedAt(assessment?.updatedAt)

  const { sum, maximumScore, subScaleScores } = getAssessmentScore(
    assessment?.data?.answers,
    metadata
  )
  const { interpretation, color } = assessmentDetails.getInterpretation
    ? assessmentDetails.getInterpretation(sum)
    : { interpretation: '', color: '#f5f5f5' }

  const interpretationMessage = () => {
    if (isAce) {
      return `Result: ${interpretation}`
    }
    if (interpretation) {
      return `Sympom Severity: ${interpretation}`
    }
    return ''
  }

  return (
    <Stack direction="column" spacing={1}>
      <span>
        <ButtonGroup
          size="large"
          disabled
          sx={{
            mx: 2,
            border: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Button sx={{ fontSize: '1rem', px: 2 }}>Completion Date: {updatedAt}</Button>
          <Button
            sx={{
              backgroundColor: addOpacityToRgb(color, 0.5),
              px: 2,
            }}
          >
            Score: {sum} out of {maximumScore}
          </Button>
          {!isBBCSS && (
            <Button
              sx={{
                backgroundColor: addOpacityToRgb(color, 0.5),
                px: 3,
              }}
            >
              {interpretationMessage()}
            </Button>
          )}
        </ButtonGroup>
      </span>

      {!!subScaleOrder.length && (
        <span>
          <ButtonGroup
            sx={{
              mx: 2,
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
            disabled
          >
            <Button
              sx={{
                fontSize: '1rem',
                px: 2,
                minWidth: '5rem !important',
              }}
            >
              Result
            </Button>
            {subScaleOrder.map((subScale) => {
              const roundedScore = Math.round(subScaleScores[subScale])
              const nanFilteredScore = isNaN(roundedScore) ? '-' : roundedScore
              return (
                <Button>
                  <Stack direction="row" alignContent="center">
                    <Typography
                      variant="body2"
                      sx={{ fontSize: '0.75rem', margin: 'auto !important' }}
                    >
                      {subScale}:{' '}
                    </Typography>
                    <Typography sx={{ margin: 'auto !important', fontSize: '1rem', px: '0.5rem' }}>
                      {nanFilteredScore}
                    </Typography>
                  </Stack>
                </Button>
              )
            })}
          </ButtonGroup>
        </span>
      )}
    </Stack>
  )
}