import React from 'react'
import { Typography } from '@mui/material'

export const sspCardConfigs = [
  {
    title: 'Annual Upfront Plan',
    costPerMonth: 125,
    description: <Typography>Prepay $1,499 today, billed annually</Typography>,
    subDescription: ({ hasActiveFocusSubscription }) => (
      <Typography
        sx={{
          span: { color: '#ce0093' },
        }}
        variant="body2"
      >
        {hasActiveFocusSubscription && (
          <>
            Save an <span>additional $500</span> if you have an active ILS yearly subscription!
          </>
        )}
      </Typography>
    ),
    link: '/purchase/products',
    skus: ['sspYearToYear_Yearly'],
    isActive: ({
      hasOrgSspCertificationCompleted,
      hasActiveSspSubscription,
      hasSspCanceledSubscription,
    }) =>
      (hasOrgSspCertificationCompleted && !hasActiveSspSubscription) || hasSspCanceledSubscription,
    testName: 'ssp-upfront-sub-card',
  },
  {
    title: 'Annual Monthly Plan',
    costPerMonth: 139,
    description: <Typography>12-month subscription, billed monthly</Typography>,
    subDescription: ({ hasActiveFocusSubscription }) => (
      <Typography
        sx={{
          span: { color: '#ce0093' },
        }}
        variant="body2"
      >
        {hasActiveFocusSubscription && (
          <>
            Save an <span>additional $500</span> if you have an active ILS yearly subscription!
          </>
        )}
      </Typography>
    ),
    link: '/purchase/products',
    skus: ['sspMonthToMonth_Yearly'],
    isActive: ({
      hasOrgSspCertificationCompleted,
      hasActiveSspSubscription,
      hasSspCanceledSubscription,
    }) =>
      (hasOrgSspCertificationCompleted && !hasActiveSspSubscription) || hasSspCanceledSubscription,
    testName: 'ssp-monthly-sub-card',
  },
  {
    title: '3-Month Plan',
    costPerMonth: 169,
    description: <Typography>3-month subscription, billed monthly</Typography>,
    subDescription: () => true, // fill out bottom of card
    link: '/purchase/products',
    skus: ['sspMonthToMonth_3Monthly'],
    isActive: ({
      hasOrgSspCertificationCompleted,
      hasActiveSspSubscription,
      hasSspCanceledSubscription,
    }) =>
      (hasOrgSspCertificationCompleted && !hasActiveSspSubscription) || hasSspCanceledSubscription,
    testName: 'ssp-3month-sub-card',
  },
  {
    title: 'Annual Upfront Plan',
    costPerMonth: 125,
    description: <Typography>Prepay $1,499 today, billed annually, auto-renewal</Typography>,
    trainingMessage: (
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          span: { color: '#ce0093' },
        }}
      >
        +$249 <span>(save $100)</span> for Foundational SSP Training
      </Typography>
    ),
    subDescription: ({ hasActiveFocusSubscription }) => (
      <>
        <Typography variant="subtitle2" color="#ce0093" fontWeight={500}>
          For a limited time, get the Road to Regulation bundle!***
        </Typography>
        <br />
        <Typography
          sx={{
            span: { color: '#ce0093' },
          }}
          variant="body2"
        >
          {hasActiveFocusSubscription && (
            <>
              Save an <span>additional $500</span> if you have an active ILS yearly subscription!
            </>
          )}
        </Typography>
      </>
    ),
    link: '/purchase/products',
    skus: ['sspCertification', 'sspYearToYear_Yearly', 'SSPPromoQ32024'],
    isActive: ({ hasSspCertification, hasActiveSspSubscription }) =>
      !hasSspCertification && !hasActiveSspSubscription,
    testName: 'ssp-upfront-bundle-card',
  },
  {
    title: 'Annual Monthly Plan',
    costPerMonth: 139,
    description: <Typography>12-month subscription, billed monthly, auto-renewal</Typography>,
    trainingMessage: (
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          span: { color: '#ce0093' },
        }}
      >
        +$249 <span>(save $100)</span> for Foundational SSP Training
      </Typography>
    ),
    subDescription: ({ hasActiveFocusSubscription }) => (
      <>
        <Typography variant="subtitle2" color="#ce0093" fontWeight={500}>
          For a limited time, get the Road to Regulation bundle!***
        </Typography>
        <br />
        <Typography
          sx={{
            span: { color: '#ce0093' },
          }}
          variant="body2"
        >
          {hasActiveFocusSubscription && (
            <>
              Save an <span>additional $500</span> if you have an active ILS yearly subscription!
            </>
          )}
        </Typography>
      </>
    ),
    link: '/purchase/products',
    skus: ['sspCertification', 'sspMonthToMonth_Yearly_Bundle', 'SSPPromoQ32024'],
    isActive: ({ hasSspCertification, hasActiveSspSubscription }) =>
      !hasSspCertification && !hasActiveSspSubscription,
    testName: 'ssp-monthly-bundle-card',
  },
  {
    title: '3-Month Plan',
    costPerMonth: 169,
    description: <Typography>3-month subscription, billed monthly, auto-renewal</Typography>,
    trainingMessage: (
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        +$349 for Foundational SSP Training
      </Typography>
    ),
    subDescription: () => true, // fill out bottom of card
    link: '/purchase/products',
    skus: ['sspCertification', 'sspMonthToMonth_3Monthly_Bundle'],
    isActive: ({ hasSspCertification, hasActiveSspSubscription }) =>
      !hasSspCertification && !hasActiveSspSubscription,
    testName: 'ssp-3month-bundle-card',
  },
]

export const focusCardConfigs = [
  {
    title: 'Annual Upfront Plan',
    costPerMonth: 125,
    description: <Typography variant="subtitle1">Prepay $1,499 today, billed annually</Typography>,
    subDescription: ({ hasActiveSspSubscription }) => (
      <>
        <Typography variant="subtitle2">
          Includes One Starter Pack: Hardware Kit + Integration Kit
        </Typography>
        {hasActiveSspSubscription && (
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            <br />
            Save an <span>additional $500</span> if you have an active SSP yearly subscription!
          </Typography>
        )}
      </>
    ),
    link: '/purchase/shipping',
    skus: ['focusYearToYear_Yearly', 'FOCUS-STRT'],
    isActive: ({
      hasOrgFocusCertificationCompleted,
      hasActiveFocusSubscription,
      hasAllCanceledFocusSubscription,
    }) =>
      (hasOrgFocusCertificationCompleted && !hasActiveFocusSubscription) ||
      hasAllCanceledFocusSubscription,
    testName: 'ils-upfront-sub-card',
  },
  {
    title: 'Annual Monthly Plan',
    costPerMonth: 139,
    description: <Typography variant="subtitle1">12-month subscription, billed monthly</Typography>,
    subDescription: ({ hasActiveSspSubscription }) => (
      <>
        <Typography variant="subtitle2">
          Includes One Starter Pack: Hardware Kit + Integration Kit
        </Typography>
        {hasActiveSspSubscription && (
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            <br />
            Save an <span>additional $500</span> if you have an active SSP yearly subscription!
          </Typography>
        )}
      </>
    ),
    link: '/purchase/shipping',
    skus: ['focusMonthToMonth_Yearly', 'FOCUS-STRT'],
    isActive: ({
      hasOrgFocusCertificationCompleted,
      hasActiveFocusSubscription,
      hasAllCanceledFocusSubscription,
    }) =>
      (hasOrgFocusCertificationCompleted && !hasActiveFocusSubscription) ||
      hasAllCanceledFocusSubscription,
    testName: 'ils-monthly-sub-card',
  },

  {
    title: 'Annual Upfront Plan',
    costPerMonth: 125,
    description: <Typography variant="subtitle1">Prepay $1,499 today, billed annually</Typography>,
    trainingMessage: (
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          span: { color: '#ce0093' },
        }}
      >
        +$149 <span>(save $50)</span> for Foundational ILS Training
      </Typography>
    ),
    subDescription: ({ hasActiveSspSubscription }) => (
      <>
        <Typography variant="subtitle2" color="#ce0093">
          For a limited time, get the Road to Regulation bundle!
        </Typography>
        <br />
        <Typography variant="subtitle2" color="#ce0093">
          Plus: $150 off MedBridge Education or Premium Subscriptions***
        </Typography>
        <br />
        <Typography variant="subtitle2">
          Includes One Starter Pack: Hardware Kit + Integration Kit
        </Typography>
        {hasActiveSspSubscription && (
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            <br />
            Save an <span>additional $500</span> if you have an active SSP yearly subscription!
          </Typography>
        )}
      </>
    ),
    link: '/purchase/shipping',
    skus: ['focusCertification', 'focusYearToYear_Yearly', 'FOCUS-STRT', 'ILSPromoQ32024'],
    isActive: ({ hasFocusCertification, hasActiveFocusSubscription }) =>
      !hasFocusCertification && !hasActiveFocusSubscription,
    testName: 'ils-upfront-bundle-card',
  },
  {
    title: 'Annual Monthly Plan',
    costPerMonth: 139,
    description: <Typography variant="subtitle1">12-month subscription, billed monthly</Typography>,
    trainingMessage: (
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          span: { color: '#ce0093' },
        }}
      >
        +$149 <span>(save $50)</span> for Foundational ILS Training
      </Typography>
    ),
    subDescription: ({ hasActiveSspSubscription }) => (
      <>
        <Typography variant="subtitle2" color="#ce0093">
          For a limited time, get the Road to Regulation bundle!
        </Typography>
        <br />
        <Typography variant="subtitle2" color="#ce0093">
          Plus: $150 off MedBridge Education or Premium Subscriptions***
        </Typography>
        <br />
        <Typography variant="subtitle2">
          Includes One Starter Pack: Hardware Kit + Integration Kit
        </Typography>
        {hasActiveSspSubscription && (
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            <br />
            Save an <span>additional $500</span> if you have an active SSP yearly subscription!
          </Typography>
        )}
      </>
    ),
    link: '/purchase/shipping',
    skus: ['focusCertification', 'focusMonthToMonth_Yearly_Bundle', 'FOCUS-STRT', 'ILSPromoQ32024'],
    isActive: ({ hasFocusCertification, hasActiveFocusSubscription }) =>
      !hasFocusCertification && !hasActiveFocusSubscription,
    testName: 'ils-monthly-bundle-card',
  },
]
