/**
 * This component will house all the welcome cards
 */
import React from 'react'
import {
  Grid,
  Card,
  CardContent,
  Typography as MuiTypography,
  Active,
  CardHeader,
} from 'components'
import { useSelector } from 'react-redux'
import { plural } from 'utils/client-pluralization'
import LINKS from 'utils/constants/links'

const Typography = ({ children, ...props }) => {
  return (
    <MuiTypography sx={{ color: '#fff' }} variant="body2" {...props}>
      {children}
    </MuiTypography>
  )
}

export const WelcomeCard = ({ id, dataTestLabel, title, children }) => {
  return (
    <Grid item xs={12} sm={4} lg={4} key={id}>
      <Card
        sx={{
          height: '100%',
          background: 'linear-gradient(90deg, rgba(38,70,83,1) 0%, rgba(42,157,143,1) 100%)',
        }}
        elevation={0}
        data-test={dataTestLabel}
      >
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: 'h5' }}
          sx={{
            color: '#fff',
          }}
        />
        <CardContent data-test="dashboard-card-body2" sx={{ color: '#fff' }}>
          {children}
        </CardContent>
      </Card>
    </Grid>
  )
}

export const ProviderWelcomeCard = ({ hasCompletedSspCertification, sspInTrainingOnly }) => {
  const { activeClients = 0, orgActiveClients, hasMultipleProviders } = useSelector(
    (state) => state.activeClients
  )
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const { hasSspProducts } = useSelector((state) => state.ff)

  return (
    <WelcomeCard id="welcome-card2" dataTestLabel="dashboard-card-welcome-card-provider">
      {hasSspProducts &&
        hasCompletedSspCertification &&
        !showNewSubscriptionPlan &&
        !sspInTrainingOnly && (
          <>
            <Typography>
              You have {activeClients} <Active /> client{plural(activeClients)}
            </Typography>
            {hasMultipleProviders && (
              <Typography>
                Your organization has {orgActiveClients} <Active /> client{plural(activeClients)}
              </Typography>
            )}
          </>
        )}
      <Typography className="font-semibold" pt={4}>
        We're here to help!
      </Typography>
      <Typography>Have questions, in need of resources, technical or clinical support?</Typography>
      <Typography>
        <a
          className="underline text-white font-semibold pr-1 text-base"
          target="_blank"
          rel="noopener noreferrer"
          href={LINKS.support.youcanbookme}
        >
          Book a call
        </a>
        with our Client Success team or{' '}
        <a
          className="underline text-white pr-1 text-base"
          target="_blank"
          rel="noopener noreferrer"
          href={LINKS.support.homepageUrl}
        >
          access our Help Center
        </a>
        .
      </Typography>
    </WelcomeCard>
  )
}

export const ClientWelcomeCard = () => {
  return (
    <WelcomeCard id="welcome-card2" dataTestLabel="dashboard-card-welcome-card-provider">
      <Typography>Welcome to MyUnyte, your access to all things for Unyte Health.</Typography>
    </WelcomeCard>
  )
}
